import React from 'react'
import imagen2 from  '../../assets/img2.jpeg'
import meetfresh from '../../assets/meet_fresh.mp4';
import styles from './TangramSlider.module.scss'
import Slider from "react-slick";
import { useLocation } from 'react-router-dom';

const TangramSlider = () => {

  const location = useLocation()

  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnFocus: false,
    pauseOnHover: false,
    swipe: false
  };

  return location.pathname!=="/location6" ?

    <div className={styles.slider}>

      <Slider {...settings}>
      
          <div className={styles.item}>

            <video controls={false} autoPlay={true} muted={true} loop={true}>

              <source src={meetfresh} type="video/mp4"/>

            </video>

          </div>

          <div className={styles.item}>

            <img src={imagen2} alt="galeria"/>
            <div className={styles.text}>Regal Cinemas</div>

          </div>

      </Slider>
      
    </div>
    
  :null
}

export default TangramSlider