import React from 'react'
import styles from './Imglandscape.module.scss'
import img1 from '../../assets/img1.svg'
import meetfresh from '../../assets/meet_fresh.mp4';
import img2 from '../../assets/img2.jpeg'

const Imglandscape = () => {
  
  return (

    <div className={styles.landscape}>

        <div className={styles.item}>

        <video controls={false} autoPlay={true} muted={true} loop={true}>

          <source src={meetfresh} type="video/mp4"/>

        </video>

        </div>

        <div className={styles.item}>

          <img src={img2} alt="galeria"/>
          <div className={styles.text}>Regal Cinemas</div>

        </div>

    </div>

  )
}

export default Imglandscape