import styles from "./Map.module.scss"
import React, {useState} from 'react'
import icon from '../../assets/floor_icon.svg'



const Map = ({first,ground,selector}) => {

    const [currentMap,setCurrentMap] = useState(selector ? selector : "ground")

  return (

    <div className={styles.container}>

        <h1>DIRECTORY</h1>

        <div className={styles.mapa}>
        
            {currentMap === "ground" ? 
                <img src={ground} alt="mapa1" data-mapa="ground"/>
            :
                <img src={first} alt= "mapa2" data-mapa="first"/>
            }
        
        </div>

        <div className={styles.selector}>

            <div className={currentMap === "ground" ? `${styles.item} ${styles.active}`:styles.item} onClick={ () => {setCurrentMap("ground")}}>
                
                <div className={styles.icon}>
                    <img src={icon} alt="icon"/>
                </div>

                <div className={styles.text}>Ground Floor</div>
                
            </div>
            
            <div className={currentMap === "first" ? `${styles.item} ${styles.active}`:styles.item} onClick={ () => {setCurrentMap("first")}}>

                <div className={styles.icon}>
                    <img src={icon} alt="icon"/>
                </div>

                <div className={styles.text}>First Floor</div>
                
            </div>
        </div>
    </div>
  )
}

export default Map