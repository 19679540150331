import React from 'react'
import styles from './Header.module.scss'
import Reloj from './Reloj/Reloj'
import logo from '../../assets/logo.svg'


const Header = () => {
  
  
    return (

        <div className={styles.container}>

            <Reloj/>
            
            <img src={logo} alt="logo"/>
            
        </div>
    )
}

export default Header