import './App.css';
import styles from './App.module.scss';
import Header from './components/Header/Header';
import Map from './components/Map/Map';
import TangramSlider from './components/Slider/TangramSlider';
import Imglandscape from './components/Imglandscape/Imglandscape'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import location1_ground from './assets/location1_ground.svg';
import location1_first from './assets/location1_first.svg';
import location2_ground from './assets/location2_ground.svg';
import location2_first from './assets/location2_first.svg';
import location3_ground from './assets/location3_ground.svg';
import location3_first from './assets/location3_first.svg';
import location4_ground from './assets/location4_ground.svg';
import location4_first from './assets/location4_first.svg';
import location5_ground from './assets/location5_ground.svg';
import location5_first from './assets/location5_first.svg';
import location6_ground from './assets/landscape_ground.svg';
import location6_first from './assets/landscape_first.svg';


function App() {

  return (

   <BrowserRouter>
      <div className={styles.App}>
    
        <Header/>

        <Routes>

          <Route path='/location1' element={<Map first={location1_first} ground={location1_ground}/>} />

          <Route path='/location2' element={<Map first={location2_first} ground={location2_ground}/>} />
            
          <Route path='/location3' element={<Map first={location3_first} ground={location3_ground} selector={"first"}/>} />
          
          <Route path='/location4' element={<Map first={location4_first} ground={location4_ground} selector={"first"}/>} />
           
          <Route path='/location5' element={<Map first={location5_first} ground={location5_ground} selector={"first"}/>} />

          <Route path='/location6' element={

            <div className={styles.Landscape}>

              <div className={styles.Columna1}>

                <Map first={location6_first} ground={location6_ground}/>

              </div>

              <div className={styles.Columna2}>

                <Imglandscape/>
                
              </div>
              
            </div>

          }/>

        </Routes>

        <TangramSlider/>
  
      </div>
   </BrowserRouter>

  );
}

export default App;
