import React, {useState, useEffect} from 'react';
import styles from "./Reloj.module.scss";


function convertTZ(date, tzString) {
  
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
}

const Reloj = () => {

  const [hora, sethora]= useState("00");
  const [min, setmin]= useState("00");
  const [seg,setseg]= useState("00");
  const [session, setsession] = useState("AM");

  useEffect(() => {

    let date = new Date(); 
    const convertedDate = convertTZ(date, "America/New_York") 
    let hh = convertedDate.getHours();
    let mm = convertedDate.getMinutes();
    let ss = convertedDate.getSeconds();
    let session = "AM";
  
    if(hh >= 12){
      session = "PM";
    }
    
    if(hh === 0){
        hh = 12;
    }

    if(hh > 12){
        hh = hh - 12;
     }

     hh = (hh < 10) ? "0" + hh : hh;
     mm = (mm < 10) ? "0" + mm : mm;
     ss = (ss < 10) ? "0" + ss : ss;
      
    setTimeout(function(){ 
        sethora(hh);
        setmin(mm);
        setseg(ss);
        setsession(session);
    }, 1000);

  }, [seg])

  return (

    <div className={styles.Reloj}>{hora}:{min} {session}</div>
    
  )
}

export default Reloj